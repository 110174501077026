<template>
	<div class="footers">
		<footer class="main-footer">
			<div class="row">
				<div class="columns column3">
					<div class="footer-logo" />
				</div>
				<div class="columns column3">
					<h4>{{ $t('contact') }}</h4>
					<p class="address">
						<span>{{ defaults[locale].website.street }} {{ defaults[locale].website.number }}</span>
						<span>{{ defaults[locale].website.postalCode }} {{ defaults[locale].website.city }}</span>
						<span>{{ defaults[locale].website.country }}</span>
						<a :href="`tel:${$phoneRaw(defaults[locale].website.phone)}`">
							<font-awesome-icon class="icon icon-phone" icon="fal fa-phone" size="1x" />
							<span>{{ defaults[locale].website.phone }}</span>
						</a>
						<a :href="`mailto:${defaults[locale].website.email}`">
							<font-awesome-icon class="icon icon-mail" icon="fal fa-envelope" size="1x" />
							<span>{{ defaults[locale].website.email }}</span>
						</a>
					</p>
					<div class="socials">
						<a
							v-for="medium in socials"
							:key="medium.ID"
							:class="medium.platform"
							:href="medium.url"
							:aria-label="medium.platform"
							target="_blank"
						>
							<font-awesome-icon
								v-if="!['tripadvisor', 'zoover'].includes(medium.platform)"
								:icon="`fa-brands fa-${medium.platform}`"
								size="1x"
							/>
						</a>
					</div>
				</div>
				<div class="columns column3">
					<div v-parse-links v-html="defaults[locale].homepage.footerContent" />
				</div>
				<div class="columns column3">
					<h4>{{ $t('quickLinks') }}</h4>
					<p>
						<nuxt-link v-for="item in footermenu[locale]" :key="item.filename" :to="item.filename">
							{{ item.header }}
						</nuxt-link>
					</p>
				</div>
			</div>
		</footer>
		<footer class="author-footer">
			<div class="row">
				<div class="columns column12">
					<div class="border" />
					<p>
						<a
							href="https://www.becurious.com/"
							target="_blank"
							rel="noopener noreferrer"
							title="Hotel website design by becurious"
						>
							Website Design
						</a>
						<span>by: </span>
						<a href="https://www.becurious.com/" target="_blank">
							<img src="~/assets/images/logo-becurious-white.png" loading="lazy" alt="Porter logo" />
						</a>
					</p>
				</div>
			</div>
		</footer>
	</div>
</template>

<script setup>
const { data: footer } = await useWebsiteFetch('footermenu');
const { footermenu } = footer.value;
const { locale } = useI18n();

const { defaults, socials, fetchDefaults } = useDefaults();

if (!defaults.value) {
	await fetchDefaults();
}
</script>

<style lang="scss" scoped>
.footers {
	color: #fff;
	background: $footer-background-color;

	a,
	span {
		color: $footer-color;
		text-decoration: none;
		transition: color 0.3s ease-in-out;
	}

	.address {
		a {
			display: flex;
			flex-flow: row nowrap;
			justify-content: flex-start;
			align-items: center;

			svg {
				margin: 0 10px 0 0;
			}
		}
	}

	h3,
	h4,
	:deep(h3),
	:deep(h4) {
		color: $cta-color;
		letter-spacing: 1px;
	}

	h4 {
		font-size: 1.3em;
	}

	.socials {
		a {
			display: inline-block;
			color: #e5c6a8;
			margin: 0 15px 0 0;
			font-size: 26px;

			&:hover,
			&:hover svg {
				color: $cta-color;
			}
		}
	}
}

.main-footer {
	padding: 75px 0;
	font-size: 0.9em;

	p {
		font-size: 0.9em;
	}

	a,
	span {
		display: block;
	}

	a:hover {
		text-decoration: underline;
	}

	.footer-logo {
		background: url('~/assets/images/logo-door74-full-brown-footer.png') no-repeat center center;
		background-size: 160px;
		width: 160px;
		height: 152px;
	}
}

.author-footer {
	font-size: 0.6em;
	padding: 5px 0;
	text-align: center;
	width: 100%;

	p {
		margin: 15px 0;
	}

	.border {
		background: #f7f7f7;
		width: 100%;
		height: 1px;
	}

	img {
		max-width: 90px;
		margin: -1px 1px 3px 2px;
	}
}

@media (max-width: 1080px) {
	footer .columns {
		margin: 0 auto 20px;
	}
}

@media (max-width: 640px) {
	.main-footer {
		padding: 30px 5px;

		.columns {
			text-align: center;
		}

		.address a {
			justify-content: center;
		}

		.footer-logo {
			margin: 0 auto 10px;
		}
	}

	.footers {
		.socials {
			a {
				margin: 0 5px;
			}
		}
	}
}
</style>
